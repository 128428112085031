<template>
    <div>
        <div class="section banner-page">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <div class="title-page">404 Page Not Found</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="container">
                <div class="row">

                    <div class="col-sm-5 col-md-3 offset-md-1">
                        <div class="people">
                            <img class="user-pic" src="http://rudhisasmito.com/demo/trainer/images/notfound-img.png"
                                 alt="">
                        </div>
                    </div>
                    <div class="col-sm-7 col-md-7">
                        <div class="margin-bottom-30"></div>
                        <h2 class="title-404">404 PAGE NOT FOUND</h2>
                        <h3 class="subtitle-404">Sorry! The page you're looking not found</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.</p>
                        <router-link class="btn btn-primary" to="/">BACK TO HOME</router-link>
                        <div class="margin-bottom-50"></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "page404"
    }
</script>

<style scoped>
</style>